import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Card, Alert } from 'antd'
import {
  isBalsamic,
  isMIPDNA,
  isTomte,
  isMIPRNA,
  isRNAFusion,
} from '../../services/helpers/constants'
import { Parents } from '../../services/interfaces'
import { getIsPlate, isSampleOld } from '../../pages/OrderForm/helpers'
import { isNil } from 'ramda'
import dayjs from 'dayjs'
import { UserContext } from '../../services/contexts/userContext'
import { getApplication } from 'services/StatusDbApi'
import { BalsamicSampleForm } from './CaseSampleForms/BalsamicSampleForm'
import { MIPDNASampleForm } from './CaseSampleForms/MIPDNASampleForm'
import { TomteSampleForm } from './CaseSampleForms/TomteSampleForm'
import { MIPRNASampleForm } from './CaseSampleForms/MIPRNASampleForm'
import { RNAFusionSampleForm } from './CaseSampleForms/RNAFusionSampleForm'

export const CaseSampleForm = ({
  index,
  remove,
  options,
  form,
  caseName,
  parentsSamples,
  setParentSamples,
  analysisType,
  skipReceptionControl,
  applicationTags,
}) => {
  const [isFormalineSource, setIsFormalineSource] = useState(false)
  const [isOtherSource, setIsOtherSource] = useState(false)
  const [hasContainer, setHasContainer] = useState(false)
  const [isPlate, setIsPlate] = useState(false)
  const [isTumour, setIsTumour] = useState(false)
  const [containerName, setContainerName] = useState<string>()
  const [application, setApplication] = useState<string>()
  const [source, setSource] = useState<string>()
  const [sampleId, setSampleId] = useState<string>()
  const [isExisting, setIsExisting] = useState<boolean>(false)
  const [receivedAt, setReceivedAt] = useState<string>()
  const userContext = useContext(UserContext)
  const [applicationObj, setApplicationObj] = useState<any>()
  const [containerNameRequirement, setContainerNameRequirement] = useState<boolean>(false)
  const [subjectId, setSubjectId] = useState<string>()

  const deleteSample = () => {
    remove(index)
    prefillConditionalFields()
  }

  const buildParentsObject = (sampleId, key = index) => {
    const localSamples = form.getFieldValue('cases')[caseName]?.samples
    const localParents: Parents = { father: [], mother: [] }
    localSamples.forEach((sample) => {
      if (sample?.name !== sampleId) {
        if (sample?.sex === 'male') localParents?.father?.push({ name: sample?.name, id: key })
        else if (sample?.sex === 'female')
          localParents?.mother?.push({ name: sample?.name, id: key })
      }
    })
    setParentSamples(localParents)
  }

  const prefillConditionalFields = useCallback(() => {
    const {
      application,
      source,
      container,
      name,
      container_name,
      internal_id,
      tumour,
      received_at,
      subject_id,
    } = form.getFieldValue('cases')[caseName]?.samples[index] || ''
    setIsExisting(!isNil(internal_id))
    if (!isNil(internal_id)) setReceivedAt(dayjs(received_at).format('YYYY-MM-DD'))
    setSampleId(name)
    setIsTumour(tumour)
    setSource(source)
    setApplication(application)
    setSubjectId(subject_id)
    if (application && skipReceptionControl) {
      getApplication(userContext, application).then((res) => {
        setApplicationObj(res)
      })
    }
    setIsPlate(getIsPlate(container))
    setContainerName(container_name)
    buildParentsObject('')
    setHasContainer(!container?.includes('No container'))
    setContainerNameRequirement(container === '96 well plate')
    if (source) {
      setIsFormalineSource(source?.includes('FFPE'))
      setIsOtherSource(source?.includes('other'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, caseName, index, userContext])

  useEffect(() => {
    prefillConditionalFields()
  }, [prefillConditionalFields])

  return (
    <Card>
      {isExisting && (
        <>
          <Alert
            message="Existing sample. Erroneous values might delay analysis."
            type="info"
            showIcon
            style={{ marginBottom: 12 }}
          />
          {isSampleOld(receivedAt) && (
            <Alert
              message="You have placed an order of old data. Due to the legacy structure of this old data we will have to manually process it. We cannot guarantee fast delivery of such data - If time is of concern please consider asking us about possible re-sequencing of existing material or sending in a new aliquot."
              type="warning"
              showIcon
              style={{ marginBottom: 12 }}
            />
          )}
        </>
      )}
      {isBalsamic(analysisType) && (
        <BalsamicSampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          setIsTumour={setIsTumour}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          isTumour={isTumour}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
        />
      )}

      {isMIPDNA(analysisType) && (
        <MIPDNASampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
          parentsSamples={parentsSamples}
        />
      )}

      {isTomte(analysisType) && (
        <TomteSampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
          parentsSamples={parentsSamples}
        />
      )}

      {isMIPRNA(analysisType) && (
        <MIPRNASampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
        />
      )}

      {isRNAFusion(analysisType) && (
        <RNAFusionSampleForm
          index={index}
          isExisting={isExisting}
          setSampleId={setSampleId}
          analysisType={analysisType}
          buildParentsObject={buildParentsObject}
          sampleId={sampleId}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
          form={form}
          caseName={caseName}
          subjectId={subjectId}
          application={application}
          options={options}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          hasContainer={hasContainer}
          containerNameRequirement={containerNameRequirement}
          setContainerName={setContainerName}
          isPlate={isPlate}
          isFormalineSource={isFormalineSource}
          containerName={containerName}
          source={source}
          applicationObj={applicationObj}
          isOtherSource={isOtherSource}
          deleteSample={deleteSample}
        />
      )}
    </Card>
  )
}
