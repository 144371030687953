import React from 'react'
import { GeneralTable } from '../../components/GeneralTable/GeneralTable'
import { getSamples } from '../../services/StatusDbApi'
import { Tag } from 'antd'
import { sexTags } from '../../services/helpers/styleHelpers'
import { Link } from 'react-router-dom'
import { formatDate, sortDate } from '../../services/helpers/helpers'
import { supportTicketURL } from '../../services/helpers/constants'

export const SamplesPage = () => {
  const columns = [
    {
      title: 'Sample id',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => (
        <>
          <Link to={`/samples/${record.internal_id}`}>{name}</Link>
        </>
      ),
      width: '20%',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => customer.name,
    },
    {
      title: 'Sex',
      dataIndex: 'sex',
      key: 'sex',
      render: (sex) => sex && <Tag color={sexTags[sex]}>{sex}</Tag>,
    },
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'Ticket',
      dataIndex: 'original_ticket',
      render: (ticket) => ticket && <a href={`${supportTicketURL}${ticket}`}>{ticket}</a>,
      key: 'original_ticket',
    },
    {
      title: 'Ordered',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.ordered_at, b.ordered_at),
    },
    {
      title: 'Received',
      dataIndex: 'received_at',
      key: 'received_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.received_at, b.received_at),
    },
    {
      title: 'Sequenced',
      dataIndex: 'last_sequenced_at',
      key: 'last_sequenced_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.last_sequenced_at, b.last_sequenced_at),
    },
  ]
  return <GeneralTable getItems={getSamples} columns={columns} title={'Samples'} />
}
