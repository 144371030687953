import { validateSubjectID } from '../../../pages/OrderForm/helpers'
import { Form, Input } from 'antd'
import CopyButton from './CopyButton'
import React from 'react'

export const SubjectIdField = ({ subjectId, index, required, caseName, sampleId, isExisting }) => {
  return (
    <>
      <Form.Item
        label="Subject ID"
        name={[index, 'subject_id']}
        rules={[
          { required: required },
          {
            message: (
              <div>
                Must be different from
                <br />
                sample or case name
              </div>
            ),
            validator: (rule, value) => {
              return validateSubjectID(value, caseName, sampleId)
            },
          },
        ]}
      >
        <Input style={{ width: 172 }} disabled={isExisting} />
      </Form.Item>
      {isExisting && <CopyButton text={subjectId} />}
    </>
  )
}
