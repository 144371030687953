import React from 'react'
import { Form, Select } from 'antd'
import { validatePanels } from 'pages/OrderForm/helpers'

export const GenePanelsField = ({ caseName, options }) => {
  return (
    <Form.Item
      label="Gene panels"
      name={[caseName, 'panels']}
      rules={[
        { required: true },
        {
          validator: (rule, userPanels) => {
            return validatePanels(userPanels, options?.panels)
          },
        },
      ]}
    >
      <Select mode="multiple" allowClear style={{ width: 280 }}>
        {options?.panels?.map((panel) => (
          <Select.Option key={panel} value={panel}>
            {panel}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
