import React from 'react'
import { Form, Input, Tooltip } from 'antd'

export const TumourPurityField = ({ index, isExisting }) => {
  return (
    <Tooltip title={'Tumour Purity (%)'}>
      <Form.Item label="Purity" name={[index, 'tumour_purity']}>
        <Input type={'number'} style={{ width: 70 }} suffix={'%'} disabled={isExisting} />
      </Form.Item>
    </Tooltip>
  )
}
