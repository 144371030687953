import React from 'react'
import { Form, Select } from 'antd'

export const ReferenceGenomeField = ({ index, isExisting, balsamicReferenceGenomes }) => {
  return (
    <Form.Item
      label="Reference genome"
      name={[index, 'reference_genome']}
      rules={[{ required: !isExisting }]}
      required={!isExisting}
    >
      <Select style={{ width: 150 }} disabled={isExisting}>
        {balsamicReferenceGenomes.map(({ text, value }) => (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
