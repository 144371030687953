import React from 'react'
import { Form, Select, Tooltip } from 'antd'

export const PhenotypeTermsField = ({ index }) => {
  return (
    <Tooltip title="Phenotype terms">
      <Form.Item label="Ph. terms" name={[index, 'phenotype_terms']}>
        <Select mode="tags" style={{ width: 210 }} />
      </Form.Item>
    </Tooltip>
  )
}
