import React from 'react'
import styles from '../Forms.module.css'
import { Divider, Row } from 'antd'
import { SampleNameField } from '../Fields/SampleNameField'
import { ControlField } from '../Fields/ControlField'
import { PriorityField } from '../Fields/PriorityField'
import { SaveButton } from '../Fields/SaveButton'
import { ApplicationField } from '../Fields/ApplicationField'
import { OrganismField } from '../Fields/OrganismField'
import { OtherOrganismField } from '../Fields/OtherOrganismField'
import { ReferenceGenomeStandaloneField } from '../Fields/ReferenceGenomeStandaloneField'
import { CommentField } from '../Fields/CommentField'
import { ElutionBufferField } from '../Fields/ElutionBufferField'
import { ExtractionMethodField } from '../Fields/ExtractionMethodField'
import { PreProcessingMethodField } from '../Fields/PreProcessingMethodField'
import { PrimerField } from '../Fields/PrimerField'
import { RegionField } from '../Fields/RegionField'
import { SelectionCriteriaField } from '../Fields/SelectionCriteriaField'
import { CollectionDateField } from '../Fields/CollectionDateField'
import { OriginalLabField } from '../Fields/OriginalLabField'
import { ContainerField } from '../Fields/ContainerField'
import { ContainerNameField } from '../Fields/ContainerNameField'
import { WellPositionField } from '../Fields/WellPositionField'
import { VolumeField } from '../Fields/VolumeField'
import { ConcentrationSampleNGField } from '../Fields/ConcentrationSampleNGField'
import { QuantityNGField } from '../Fields/QuantityNGField'
import { DeleteButton } from '../Fields/DeleteButton'

export const SarsCov2Form = ({
  index,
  setSampleId,
  analysisType,
  controls,
  priorities,
  validateSample,
  setApplication,
  setApplicationObj,
  skipReceptionControl,
  userContext,
  applicationTags,
  setIsOtherOrganism,
  otherOrganism,
  form,
  organisms,
  isOtherOrganism,
  buffers,
  extractionMethods,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  hasContainer,
  containerNameRequirement,
  setContainerName,
  isPlate,
  containerName,
  plateLayout,
  remove,
  sampleId,
  isBufferRequired,
  preprocessingMethods,
  primers,
  regions,
  selectionCriteria,
  labs,
  validateWellIsFree,
}) => {
  return (
    <>
      <Row className={styles.row}>
        <SampleNameField
          index={index}
          isExisting={null}
          setSampleId={setSampleId}
          buildParentsObject={null}
        />
        <ControlField index={index} isExisting={null} controls={controls} />
        <PriorityField index={index} priorities={priorities} isExisting={null} />
        <SaveButton saveValidate={validateSample} />
      </Row>
      <Row className={styles.row}>
        <ApplicationField
          index={index}
          isExisting={null}
          setApplication={setApplication}
          setApplicationObj={setApplicationObj}
          skipReceptionControl={skipReceptionControl}
          userContext={userContext}
          applicationTags={applicationTags}
        />
        <OrganismField
          index={index}
          setIsOtherOrganism={setIsOtherOrganism}
          otherOrganism={otherOrganism}
          form={form}
          organisms={organisms}
        />
        {isOtherOrganism && <OtherOrganismField index={index} isOtherOrganism={isOtherOrganism} />}
        <ReferenceGenomeStandaloneField index={index} />
      </Row>
      <Row className={styles.row}>
        <CommentField index={index} />
      </Row>
      <Divider style={{ marginTop: '-2px' }} />
      <Row className={styles.row}>
        <ElutionBufferField
          index={index}
          isBufferRequired={isBufferRequired}
          application={null}
          skipReceptionControl={null}
          buffers={buffers}
          standalone={true}
        />
        <ExtractionMethodField index={index} extractionMethods={extractionMethods} />
      </Row>

      <PreProcessingMethodField index={index} preprocessingMethods={preprocessingMethods} />
      <PrimerField index={index} primers={primers} />
      <Row className={styles.row}>
        <RegionField index={index} regions={regions} />
        <SelectionCriteriaField index={index} selectionCriteria={selectionCriteria} />
        <CollectionDateField index={index} />
        <OriginalLabField index={index} labs={labs} />
      </Row>
      <Row className={styles.row}>
        <ContainerField
          index={index}
          prefillConditionalFields={prefillConditionalFields}
          setContainerNameRequirement={setContainerNameRequirement}
          getAvailableContainersType={getAvailableContainersType}
          analysisType={analysisType}
          required={true}
        />
        {hasContainer && (
          <>
            <ContainerNameField
              index={index}
              containerNameRequirement={containerNameRequirement}
              setContainerName={setContainerName}
              isRML={false}
            />
            {isPlate && (
              <WellPositionField
                index={index}
                form={form}
                validateWellIsFree={validateWellIsFree}
                containerName={containerName}
                plateLayout={plateLayout}
                standalone={true}
                isRML={false}
              />
            )}
            <VolumeField index={index} />
            <ConcentrationSampleNGField index={index} />
            <QuantityNGField index={index} />
          </>
        )}
      </Row>
      <DeleteButton remove={remove} index={index} sampleId={sampleId} />
    </>
  )
}
