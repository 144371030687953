import React from 'react'
import { Form, Input } from 'antd'

export const SynopsisField = ({ caseName, isExisting }) => {
  return (
    <Form.Item label="Synopsis" name={[caseName, 'synopsis']}>
      <Input disabled={isExisting} style={{ width: 312 }} />
    </Form.Item>
  )
}
