import React from 'react'
import { Form, Radio } from 'antd'

export const TissueBlockField = ({ index, tissueBlockSize }) => {
  return (
    <Form.Item label="Tissue block" name={[index, 'tissue_block_size']}>
      <Radio.Group size="small">
        {tissueBlockSize.map((status) => (
          <Radio.Button key={status} value={status}>
            {status}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}
