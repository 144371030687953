import React from 'react'
import { Form, Input, Tooltip } from 'antd'

export const FormalinFixationHoursField = ({ index }) => {
  return (
    <Tooltip title={'Formalin fixation hours'}>
      <Form.Item label="Fixation hrs" name={[index, 'formalin_fixation_time']}>
        <Input style={{ width: 35 }} />
      </Form.Item>
    </Tooltip>
  )
}
