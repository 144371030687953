import React from 'react'
import { Form, Select } from 'antd'

export const MotherField = ({ index, parentsSamples, sampleId }) => {
  return (
    <Form.Item label="Mother" name={[index, 'mother']}>
      <Select style={{ width: 182 }} allowClear>
        {parentsSamples?.mother
          ?.filter((sample) => sample.name !== sampleId)
          .map(({ name }) => (
            <Select.Option key={name} value={name}>
              {name}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  )
}
