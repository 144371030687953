import React from 'react'
import { Form, Select, Tooltip } from 'antd'

export const PhenotypeGroupsField = ({ index }) => {
  return (
    <Tooltip title="Phenotype groups">
      <Form.Item label="Ph. groups" name={[index, 'phenotype_groups']}>
        <Select mode="tags" style={{ width: 210 }} />
      </Form.Item>
    </Tooltip>
  )
}
