import React from 'react'
import { Form, Input, Tooltip } from 'antd'

export const AgeField = ({ index }) => {
  return (
    <Tooltip title="Age at sampling">
      <Form.Item label="Age" name={[index, 'age_at_sampling']}>
        <Input style={{ width: 47 }} />
      </Form.Item>
    </Tooltip>
  )
}
