import React from 'react'
import { GeneralTable } from '../../components/GeneralTable/GeneralTable'
import { getPools } from '../../services/StatusDbApi'
import { formatDate, sortDate } from '../../services/helpers/helpers'
import { supportTicketURL } from '../../services/helpers/constants'

export const PoolsPage = () => {
  const columns = [
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
      ellipsis: true,
      width: '20%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ticket',
      dataIndex: 'ticket',
      render: (ticket) => ticket && <a href={`${supportTicketURL}${ticket}`}>{ticket}</a>,
      key: 'ticket',
    },
    {
      title: 'Ordered',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.ordered_at, b.ordered_at),
    },
    {
      title: 'Received',
      dataIndex: 'received_at',
      key: 'received_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.received_at, b.received_at),
    },
    {
      title: 'Sequenced',
      dataIndex: 'sequenced_at',
      key: 'sequenced_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.sequenced_at, b.sequenced_at),
    },
    {
      title: 'Delivered',
      dataIndex: 'delivered_at',
      key: 'delivered_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.delivered_at, b.delivered_at),
    },
    {
      title: 'Invoiced',
      dataIndex: 'invoiced_at',
      key: 'invoiced_at',
      render: (date) => formatDate(date),
      sorter: (a, b) => sortDate(a.invoiced_at, b.invoiced_at),
    },
  ]
  return <GeneralTable getItems={getPools} columns={columns} title={'Pools'} />
}
