import React from 'react'
import { Form, Input } from 'antd'

export const CaseNameField = ({ caseName, isExisting }) => {
  return (
    <Form.Item
      label="Case name"
      name={[caseName, 'name']}
      required
      rules={[
        {
          required: true,
          message: 'required',
        },
        {
          message: 'Can include only numbers, letters and dash',
          pattern: new RegExp(/^[a-zA-Z0-9-]*$/),
        },
      ]}
    >
      <Input minLength={2} disabled={isExisting} style={{ width: 240 }} />
    </Form.Item>
  )
}
