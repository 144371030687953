import React from 'react'
import { Form, Input, Tooltip } from 'antd'

export const WeeksAfterFixationField = ({ index }) => {
  return (
    <Tooltip title={'Weeks after fixation'}>
      <Form.Item label="Weeks fix." name={[index, 'post_formalin_fixation_time']}>
        <Input style={{ width: 35 }} />
      </Form.Item>
    </Tooltip>
  )
}
