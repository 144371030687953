import React from 'react'
import { Form, Radio } from 'antd'

export const StatusField = ({ isExisting, index, carrierStatus }) => {
  return (
    <Form.Item required={!isExisting} rules={[{ required: !isExisting }]} name={[index, 'status']}>
      <Radio.Group size="small">
        {carrierStatus.map(({ status }) => (
          <Radio.Button key={status} value={status}>
            {status}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}
